export default {
  es: {
    'app.promociones.form.cantidad': 'Inmuebles de tipo: {name}',
    'app.promociones.form.err.nombre': 'El nombre es un campo obligatorio',
    'app.promociones.form.err.zona': 'La zona es un campo obligatorio',
    'app.promociones.form.historico.reservas': 'Tipos de inmuebles previamente reservado, indicar cantidad, cero por defecto',
    'app.promociones.form.historico.venta': 'Tipos de inmuebles previamente vendido, indicar cantidad, cero por defecto',
    'app.promociones.form.name': 'Nombre',
    'app.promociones.form.save': 'Guardar',
    'app.promociones.form.submit.success': 'La promoción se ha guardado correctamente. Vas a ser redirigirdo al listado de promociones en {timeout} segundos.',
    'app.promociones.form.tipos-inmueble': 'Tipos de inmuebles, indicar cantidad, cero por defecto',
    'app.promociones.form.title-anadir': 'Añadir promoción',
    'app.promociones.form.title-editar': 'Editar promoción',
    'app.promociones.form.zona.pagination': 'ver más resultados',
    'app.promociones.form.zona': 'Zona',
    'app.promociones.index.column.active.false': 'inactivo',
    'app.promociones.index.column.active.true': 'activo',
    'app.promociones.index.column.active': 'Activo',
    'app.promociones.index.column.home.false': 'ocultar',
    'app.promociones.index.column.home.true': 'mostrar',
    'app.promociones.index.column.home': 'Portada',
    'app.promociones.index.column.id': 'ID',
    'app.promociones.index.column.name': 'Nombre',
    'app.promociones.index.column.zona': 'Zona',
    'app.promociones.index.display-active': 'Mostrar promociones inactivas',
    'app.promociones.index.hide-active': 'Ocultar promociones inactivas',
    'app.promociones.index.nuevo': 'Añadir promoción',
    'app.promociones.index.title': 'Listado de promociones',
  },
};