
const APP_ALERTS_ADD = 'APP_ALERTS_ADD';
const APP_ALERTS_REMOVE = 'APP_ALERTS_REMOVE';
const APP_ALERTS_REMOVE_ALL = 'APP_ALERTS_REMOVE_ALL';

export default {
  APP_ALERTS_ADD,
  APP_ALERTS_REMOVE,
  APP_ALERTS_REMOVE_ALL,
};
