export default {
  es: {
    'app.visitas.form.apellido_1': 'Primer apellido',
    'app.visitas.form.apellido_2': 'Segundo apellido',
    'app.visitas.form.conociste': 'Cómo nos conociste',
    'app.visitas.form.contactado.mail': 'Email',
    'app.visitas.form.contactado.no': 'No desea ser contactado',
    'app.visitas.form.contactado.phone': 'Teléfono',
    'app.visitas.form.contactado': 'Cómo desea ser contactado',
    'app.visitas.form.email': 'Email',
    'app.visitas.form.err.apellido_1': 'El primer apellido es un campo obligatorio',
    'app.visitas.form.err.email': 'Por favor proporcione un email válido',
    'app.visitas.form.err.name': 'El nombre es un campo obligatorio',
    'app.visitas.form.err.promociones_id': 'La promoción es un campo obligatorio',
    'app.visitas.form.err.telefono': 'El teléfono es un campo obligatorio',
    'app.visitas.form.fecha_visita': 'Fecha visita',
    'app.visitas.form.modal-reservas.close': 'guardar',
    'app.visitas.form.modal-reservas.title': 'Seleccione el inmueble reservado',
    'app.visitas.form.months.0': 'Enero',
    'app.visitas.form.months.1': 'Febrero',
    'app.visitas.form.months.10': 'Noviembre',
    'app.visitas.form.months.11': 'Diciembre',
    'app.visitas.form.months.2': 'Marzo',
    'app.visitas.form.months.3': 'Abril',
    'app.visitas.form.months.4': 'Mayo',
    'app.visitas.form.months.5': 'Junio',
    'app.visitas.form.months.6': 'Julio',
    'app.visitas.form.months.7': 'Agosto',
    'app.visitas.form.months.8': 'Septiembre',
    'app.visitas.form.months.9': 'Octubre',
    'app.visitas.form.name': 'Nombre',
    'app.visitas.form.observaciones': 'Observaciones',
    'app.visitas.form.promociones': 'Promoción',
    'app.visitas.form.publicidad': 'Desea recibir publicidad',
    'app.visitas.form.save': 'Guardar',
    'app.visitas.form.si': 'Sí',
    'app.visitas.form.status.anulacion': 'Anulación',
    'app.visitas.form.status.compra': 'Compra',
    'app.visitas.form.status.primera': 'Visita',
    'app.visitas.form.status.reserva': 'Reserva',
    'app.visitas.form.status.web': 'Web',
    'app.visitas.form.status.telefono': 'Teléfono',
    'app.visitas.form.status.cita-previa': 'Cita previa',
    'app.visitas.form.status': 'Estatus',
    'app.visitas.form.submit.success': 'La visita se ha guardado correctamente. Vas a ser redirigirdo al listado de visitas en {timeout} segundos.',
    'app.visitas.form.telefono': 'Teléfono',
    'app.visitas.form.title-anadir': 'Añadir visita',
    'app.visitas.form.title-editar': 'Editar visita',
    'app.visitas.form.venta': 'Venta asociada a la visita: {promocion} {tipo}',
    'app.visitas.form.zona.pagination': 'ver más resultados',
    'app.visitas.index.column.comercial': 'Comercial',
    'app.visitas.index.column.deleted': 'Borrar',
    'app.visitas.index.column.email': 'Email',
    'app.visitas.index.column.fecha_visita': 'Fecha de la visita',
    'app.visitas.index.column.name': 'Nombre',
    'app.visitas.index.column.promocion': 'Promoción',
    'app.visitas.index.column.status': 'Estatus',
    'app.visitas.index.column.telefono': 'Teléfono',
    'app.visitas.index.desde': 'desde',
    'app.visitas.index.excel': 'excel',
    'app.visitas.index.filter-visitas.buscar': 'Buscar',
    'app.visitas.index.filter-visitas.clear': 'limpiar filtros',
    'app.visitas.index.filter-visitas.close': 'Cerrar',
    'app.visitas.index.filter-visitas.fechas': 'Fechas',
    'app.visitas.index.filter-visitas.filter': 'filtra visitas',
    'app.visitas.index.filter-visitas.promociones': 'Promociones',
    'app.visitas.index.filter-visitas.comercial': 'Comercial',
    'app.visitas.index.filter-visitas.status': 'Estado',
    'app.visitas.index.filter-visitas.title': 'Filtrar visitas',
    'app.visitas.index.hasta': 'hasta',
    'app.visitas.index.modal.body': '¿Está seguro de borrar la visita? esta acción no tiene vuelta atrás',
    'app.visitas.index.modal.close': 'Cerrar',
    'app.visitas.index.modal.delete': 'Borrar',
    'app.visitas.index.modal.title': 'Borrar visita',
    'app.visitas.index.newsletter': 'newsletter',
    'app.visitas.index.no-results': 'No existen visitas para los criterios seleccionados',
    'app.visitas.index.nuevo': 'Añadir visita',
    'app.visitas.index.title': 'Listado de visitas',
    'app.visitas.form.reserva': 'Reserva asociada a la visita: {promocion} {tipo}',
    'app.visitas.form.marcar-vendido': 'Confirmar reserva como venta',
  },
};