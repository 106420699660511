const RECEIVE_LOGIN_ERROR = 'RECEIVE_LOGIN_ERROR';
const RECEIVE_LOGIN_SUCCESS = 'RECEIVE_LOGIN_SUCCESS';
const RECEIVE_LOGOUT_SUCCESS = 'RECEIVE_LOGOUT_SUCCESS';
const REQUEST_LOGIN = 'REQUEST_LOGIN';

export default {
  RECEIVE_LOGIN_ERROR,
  RECEIVE_LOGIN_SUCCESS,
  RECEIVE_LOGOUT_SUCCESS,
  REQUEST_LOGIN,
};
