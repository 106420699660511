export default {
  es: {
    'app.users.form.email': 'Email',
    'app.users.form.err.email': 'Por favor proporcione un email válido',
    'app.users.form.err.name': 'El nombre es un campo obligatorio',
    'app.users.form.err.password': 'La contraseña es obligatoria al crear un usuario y debe de ser al menos de 6 caracteres',
    'app.users.form.err.rePassword': 'Ambas contraseñas deben coincidir',
    'app.users.form.name': 'Nombre',
    'app.users.form.password': 'Contraseña',
    'app.users.form.rePassword': 'Contraseña',
    'app.users.form.save': 'Guardar',
    'app.users.form.submit.success': 'El usurio se ha guardado correctamente. Vas a ser redirigirdo al listado de usuarios en {timeout} segundos.',
    'app.users.form.title-anadir': 'Añadir usuario',
    'app.users.form.title-editar': 'Editar usuario',
    'app.users.form.zona.pagination': 'ver más resultados',
    'app.users.index.column.active.false': 'inactivo',
    'app.users.index.column.active.true': 'activo',
    'app.users.index.column.active': 'Activo',
    'app.users.index.column.created_at': 'Fecha de creación',
    'app.users.index.column.email': 'Email',
    'app.users.index.column.id': 'ID',
    'app.users.index.column.last_login': 'Último acceso a la APP',
    'app.users.index.column.name': 'Nombre',
    'app.users.index.column.superuser': 'Administradores',
    'app.users.index.nuevo': 'Añadir usuario',
    'app.users.index.title': 'Listado de usuarios',
  },
};