export default {
  en: {},
  es: {
    'app.accounts-list.pagination.goto-page': 'Ir a la página {value, number}',
    'app.accounts-list.pagination.next-page': 'Siguiente',
    'app.accounts-list.pagination.page': 'Página {value, number}',
    'app.accounts-list.pagination.previous': 'Anterior',
    'app.conociste.cono': 'Conocidos',
    'app.conociste.ddnava': 'Anuncio Diario de Navarra',
    'app.conociste.ddnavc': 'Clasificados Diario de Navarra',
    'app.conociste.ddnot': 'Diario de Noticias',
    'app.conociste.fotoc': 'fotocasa.com',
    'app.conociste.ideal': 'idealista.com',
    'app.conociste.lista': 'lista de interesados',
    'app.conociste.pisos': 'pisos.com',
    'app.conociste.valla': 'Valla de obra',
    'app.conociste.web': 'Página web',
    'app.conociste.oficina': 'Oficina',
  },
};