export default {
  es: {
    'app.status-promociones.index.title': 'Status de las promociones',
    'app.status-promociones.index.totales': 'TOTALES',
    'app.status-promociones.index.ventas': 'Ventas',
    'app.status-promociones.index.visitas': 'Visitas',
    'app.status-promociones.index.viviendas-libres': 'Viviendas libres',
    'app.status-promociones.index.viviendas-promocion': 'Viviendas promoción',
    'app.status-promociones.index.viviendas-reservadas': 'Viviendas reservadas',
    'app.status-promociones.index.viviendas-vendidas': 'Viviendas vendidas',
    'app.status-promociones.index.viviendas-venta': 'Viviendas a la venta',
    'app.status-promociones.screenshot.btn': 'screeshot',
    'app.status-promociones.screenshot.modal.close': 'cerrar',
    'app.status-promociones.screenshot.modal.input.name': 'Nombre del screeshot',
    'app.status-promociones.screenshot.modal.save': 'guardar',
    'app.status-promociones.screenshot.modal.title': 'Obtener screeshot',
    'app.status-promociones.screenshot.sucess-alert': 'El screenshot se ha guardado correctamente',
    'app.status-promociones.index.no-results': 'No existen datos para los criterios seleccionados',
  },
};